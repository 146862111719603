import React from 'react';
import './App.scss';
import IconReload from './reload.svg';

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <div></div>
        <div>
          <a href="/" style={{padding:"5px",display: "block"}}>
            Reload
            {/* <IconReload/> */}
            {/* <img src="{require('./reload.svg')}"/> */}
          </a>
        </div>
      </header>
      <div className="app-body">
        <div className="links">
          <div className="links-group">
            <a href="tel:*888#">*888#</a>
            <a href="tel:*1333*888#">*1333*888#</a>
          </div>
          <div className="links-group">
            <a href="tel:*1333*7#">*1333*7#</a>
          </div>
          <div className="links-group">
            <a href="tel:*1200#">*1200#</a>
          </div>
        </div>
      </div>
      <footer className="app-footer">

      </footer>
    </div>
  );
}

export default App;
